<template>
  <v-card class="nospace">
    <v-card-text class="nospace">
      <loading :active.sync="loading" />
      <v-data-table
        id="customerListTable"
        hide-actions
        :headers="headers"
        :items="items"
        :disable-initial-sort="true"
        :pagination.sync="pagination"
      >
        <template slot="items" slot-scope="props">
          <td v-if="tabFilter == 'job'">
            {{ props.item.jobId }}
            <activityLogs
              v-if="tabFilter == 'sales'"
              :type="'inquiry'"
              :id="props.item.inquiryId"
            />
            <activityLogs
              :type="'jobs'"
              v-if="tabFilter == 'job'"
              :permissions="'activity-logs-jobs'"
              :_id="props.item._id"
              :id="props.item.jobId.toString()"
            />
          </td>

          <td v-if="tabFilter == 'sales'">
            {{ props.item.inquiryId }}
            <activityLogs
              v-if="tabFilter == 'sales'"
              :permissions="'activity-logs-jobs'"
              :type="'inquiry'"
              :id="props.item.inquiryId"
            />
          </td>
          <td>
            <img
              v-if="props.item.workingCountry == 'PAK'"
              src="../../assets/pakflag.png"
              height="20px"
            /><img
              v-if="props.item.workingCountry == 'KSA'"
              src="../../assets/ksaflag.png"
              height="20px"
            /><img
              v-if="props.item.workingCountry == 'UAE'"
              src="../../assets/Uaeflag.png"
              height="20px"
            /><img
              v-if="props.item.workingCountry == 'UAE LA'"
              src="../../assets/Uaeflag.png"
              height="20px"
              style="border-radius: 50%"
            />
            <img
              v-if="props.item.workingCountry == 'OMAN'"
              src="../../assets/omanflag.png"
              height="28px"
            />
            <br />{{ props.item.workingCountry || "-" }}
          </td>
          <td v-if="tabFilter == 'job'">{{ props.item.customer }}</td>

          <td v-if="tabFilter == 'sales'">
            {{
              props.item.userId && props.item.userId.name
                ? props.item.userId.name
                : "-"
            }}
          </td>
          <td>
            {{ props.item.numberOfAssignmentsRequired }}
          </td>
          <td v-if="tabFilter == 'job'">
            {{ getTime3(props.item.pickupDate) }} {{ props.item.pickUpTime }}
          </td>
          <td v-if="tabFilter == 'sales'">
            {{ getTime3(props.item.bookingDate) }}
          </td>
          <td style="word-break: break-all">
            <v-tooltip
              top
              class="milestone"
              content-class="tooltip-op"
              color="orange"
            >
              <template slot="activator"
                >{{ props.item.sourceCity }},
                {{ props.item.sourceCountry }}</template
              >
              <span class="tooltip">{{ props.item.sourceLocation }}</span>
            </v-tooltip>
          </td>
          <td style="width: 10%">
            <v-tooltip
              top
              class="milestone"
              content-class="tooltip-op"
              color="orange"
            >
              <template slot="activator"
                >{{ props.item.destinationCity }},
                {{ props.item.destinationCountry }}</template
              >
              <span class="tooltip">{{ props.item.destinationLocation }}</span>
            </v-tooltip>
          </td>
          <td v-if="tabFilter == 'job'">
            {{ props.item.jobPrice }} {{ props.item.customerCurrency }}
          </td>
          <td v-else>
            {{ props.item.acceptedPrice }} {{ props.item.customerCurrency }}
          </td>
          <td v-if="tabFilter == 'job'">
            {{ getStatus(props.item.jobStatus) }}
          </td>
          <td v-if="tabFilter == 'sales'">{{ props.item.inquiryStatus }}</td>

          <td v-if="tabFilter == 'job'">
            <button
              target="_blank"
              v-if="props.item.jobStatus != -1 && props.item.jobStatus == 10"
              @click="open2(props.item, props.index)"
              class="cancel-btn"
            >
              Reject
            </button>
            <button
              v-if="props.item.jobStatus == 10"
              class="track-ass-btn green-button m-l-5"
              title="Approval"
              @click="jobApproval(props.item, props.index)"
            >
              Approve
            </button>
            <button
              @click="goToProfile(props.item.jobId)"
              target="_blank"
              class="track-ass-btn m-l-5"
            >
              Details
            </button>
          </td>
          <td v-if="tabFilter == 'sales'">
            <button
              target="_blank"
              v-if="props.item.inquiryStatus == 'Low Credit Limit'"
              @click="open2(props.item, props.index)"
              class="cancel-btn"
            >
              Reject
            </button>
            <button
              v-if="props.item.inquiryStatus == 'Low Credit Limit'"
              @click="inquiryApproval(props.item, props.index)"
              class="track-ass-btn green-button m-l-5"
              title="Approval"
            >
              Approve
            </button>
          </td>
        </template>
        <v-alert
          slot="no-results"
          :value="isNoSearchResult"
          color="blue"
          icon="warning"
          >Your search for "{{ search }}" found no results.</v-alert
        >
      </v-data-table>
      <pagination
        v-if="this.workingCountry"
        :totalPages="totalPages"
        :pages="this.pages"
        @pageNo="page"
        :url="`/approvals/approvalList`"
        :callback="callback"
        :componentKey="componentKey"
        :action="`tender`"
        :payload="payload"
        :type="1"
        v-on:pagination-load="checkLoading"
      />
    </v-card-text>

    <success-dialog
      :content="x.message"
      :show="x.success"
      :onclose="closeMessage"
    />
    <v-snackbar
      :timeout="6000"
      color="red darken-2"
      v-model="error"
      class="white--text"
      v-if="error"
      >{{ error }}</v-snackbar
    >
    <v-dialog
      v-model="dialog2"
      persistent
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="white--text">{{
            cancelTitle
          }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-show="!!cancelMessage" class="pa-4">
          {{ cancelMessage }}
          <v-flex pt-2 pr-1>
            <div class="subheading muted caption">
              Provide cancellation reason
            </div>
            <v-select
              class="pt-0 currencyTitle"
              v-model="cancelType"
              :items="cancellationType"
              :menu-props="{ offsetY: true }"
              label="Select your option"
              persistent-hint
              return-object
              single-line
            ></v-select>
          </v-flex>
          <v-flex>
            <div
              v-if="cancelType.value == 'Other’s'"
              class="subheading muted caption"
            >
              Reason
            </div>
            <v-text-field
              class="pt-0 currencyTitle"
              v-if="cancelType.value == 'Other’s'"
              type="text"
              single-line
              label="Enter your reason"
              required
              v-model="cancelComments"
              :value="cancelComments"
            />
          </v-flex>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-btn color="primary darken-1" text @click.native="open3">Yes</v-btn>
          <v-btn color="grey" text @click.native="cancel2">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog3"
      persistent
      :max-width="450"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar
          dark
          flat
          style="background-color: #fee9d0; color: black; padding-left: 100px"
        >
          <v-toolbar-title dark>{{ cancelTitle1 }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon
              class="heading grey--text text--darken-4"
              @click.prevent="dialog3 = false"
              >close</v-icon
            >
          </v-btn>
        </v-toolbar>
        <v-spacer class="spacerclass">
          <v-card-text
            style="text-align: center"
            v-show="!!cancelMessage1"
            class="pa-4"
          >
            <div class="pb-4">
              <img
                src="../../assets/Character-sitting.png"
                height="300"
                width="300"
              />
            </div>
            <div class="subheading">{{ cancelMessage1 }}</div>

            <div
              v-if="cancelType.value == 'Other’s'"
              class="subheading muted pt-2"
            >
              <span style="color: darkgrey">Cancellation reason:</span>
              <p style="word-break: break-all">
                {{ cancelType.value }} - {{ cancelComments }}
              </p>
            </div>
            <div v-else class="subheading muted pt-2">
              <span style="color: darkgrey">Cancellation reason:</span>
              {{ cancelType.value }}
            </div>
          </v-card-text>

          <v-card-actions class="pt-0 pb-5 justfy_c">
            <v-btn
              medium
              style="text-transform: none !important"
              color="orange white--text"
              darken-1
              text
              :loading="loading"
              @click.native="agree2"
              >DONE</v-btn
            >
          </v-card-actions>
        </v-spacer>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import _ from "lodash";
import { EventBus } from "../../event-bus.js";
import Loading from "vue-loading-overlay";
import { approvalList } from "@/constants/datatable-headers.js";
import { ApiService } from "@/services/api-service";
import moment from "moment";
import activityLogs from "../Common/activityLogs";
import pagination from "@/components/Pagination/pagination";
import SuccessDialog from "@/components/Common/SuccessDialog";
import ErrorBox from "@/components/Common/ErrorBox";
import "moment/locale/es"; // without this line it didn't work
moment.locale("en-in");
import { debuglog } from "util";
import { StorageKeys, CancellationType } from "../../constants/constants";
export default {
  created() {
    this.cancellationType = CancellationType;
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = workingCountry;
      this.loading = true;
      this.payload = {
        workingCountry: this.workingCountry,
        type: this.tabFilter,
        isSearch: "true",
      };
    }
    EventBus.$on("countryChanged", (val) => {
      this.workingCountry = val;
      this.payload.workingCountry = this.workingCountry;
    });
    this.$eventBus.$on("close-job-profile", () => {
      this.dialog = false;
      this.currentItem = null;
    });
  },
  components: {
    pagination,
    SuccessDialog,
    Loading,
    activityLogs,
    ErrorBox,
  },
  props: {
    loader: Boolean,
    tabFilter: String,
    searchText: String,
    dateRangenew: Array,
    reset: Boolean,
    updateList: Number,
  },
  watch: {
    dateRangenew: function (n, o) {
      if (!n) {
        this.$eventBus.$emit("on-load");
        this.payload = {
          type: this.tabFilter,
          isSearch: "true",
          workingCountry: this.workingCountry,
        };
      }

      if (n != "") {
        //this.$eventBus.$emit("on-load");
        this.$eventBus.$emit("on-load");
        this.loading = false;
        if (n.length && n[0] && n[1]) {
          this.startDate = moment(n[0]).format("YYYY-MM-DD");
          this.endDate = moment(n[1]).format("YYYY-MM-DD");
        }

        this.$set(this.payload, "startDate", this.startDate);

        this.payload.endDate = this.endDate;
      } else {
        this.payload = {
          type: this.tabFilter,
          workingCountry: this.workingCountry,
        };
      }
    },
    dateFilter: function (n, o) {
      if (n != "") {
        //this.$eventBus.$emit("on-load");
        this.$set(this.payload, "startDate", n.startDate);

        this.payload.endDate = n.endDate;
      } else {
        this.payload = {
          type: this.tabFilter,
          workingCountry: this.workingCountry,
        };
      }
    },
    pagination(val) {
      if (val["sortBy"] === "trucks") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "numberOfAssignmentsRequired";
          this.payload["sortType"] = -1;
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "numberOfAssignmentsRequired";
          this.payload["sortType"] = 1;
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        }
      } else if (val["sortBy"] === "date") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "pickupDate";
          this.payload["sortType"] = -1;
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "pickupDate";
          this.payload["sortType"] = 1;
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        }
      } else if (val["sortBy"] === "status") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "jobPrice";
          this.payload["sortType"] = -1;
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "jobPrice";
          this.payload["sortType"] = 1;
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        }
      }

      if (!val["sortBy"]) {
        delete this.payload["sortType"];
        delete this.payload["sortBy"];
        if (val["descending"] == null) {
          this.componentKey = this.componentKey + 1;
        }
      }
      deep: true;
    },
    searchText: function (n, o) {
      if (n != "") {
        this.$eventBus.$emit("on-load");
        this.$set(this.payload, "searchText", n);
      } else {
        if (this.payload.startDate) {
          this.payload = {
            isSearch: "true",
            type: this.tabFilter,
            startDate: this.dateFilter.startDate,
            endDate: this.dateFilter.endDate,
            workingCountry: this.workingCountry,
          };
        } else {
          this.payload = {
            isSearch: "true",
            type: this.tabFilter,
            workingCountry: this.workingCountry,
          };
        }
      }
    },
    updateList: function (n) {
      this.componentKey = this.componentKey + 1;
    },
    tabFilter: function (n, o) {
      this.loading = true;
      if (n != "") {
        this.$set(this.payload, "type", n);
      } else {
        this.payload = {
          isSearch: "true",
          type: this.tabFilter,
          workingCountry: this.workingCountry,
        };
      }
    },
    reset: function (n, o) {
      if (n == true) {
        this.$eventBus.$emit("on-load");
        this.payload = {
          type: this.tabFilter,
          workingCountry: this.workingCountry,
          isSearch: "true",
        };
      }
    },
    deep: true,
    immediate: true,
  },
  data() {
    return {
      cancellationType: [],
      cancelType: "",
      newJob: 0,
      newInquiry: 0,
      startDate: "",
      endDate: "",
      statusComments: "",
      cancelTitle: null,
      dialog3: false,
      cancelMessage: null,
      cancelTitle1: null,
      cancelMessage1: null,
      dialog2: false,
      cancelComments: "",
      options: {
        color: "#ffc04c",
        width: 500,
        zIndex: 200,
      },
      pagination: {},
      workingCountry: null,
      processing: false,
      componentKey: 0,
      search: "",
      error: null,
      isNoSearchResult: false,
      loading: true,
      payload: {},
      pages: 1,
      totalPages: 0,
      x: {
        message: "",
        loading: false,
        success: false,
        menu: false,
        error: null,
      },
      headers: approvalList,
      documentData: [],
      items: [],
      executives: [],
      selectedExecutive: null,
      currentIndexForCustomer: null,
    };
  },
  methods: {
    checkLoading(event) {
      if (event) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },
    refresh() {
      this.newJob = 0;
      this.newInquiry = 0;
    },

    jobApproval(value, status) {
      this.loading = true;
      let body = {
        jobId: value.jobId.toString(),
        underApproval: true,
      };
      ApiService.postData("/approvals/approveJob", body).then(
        (response) => {
          this.identify(localStorage.getItem("email"));
          this.track("Job Approval", {
            "Job Id": value.jobId,
            "Company Name/Customer Name":
              value.companyName || value.customer || value.customerEmail,
            "Job Start Date": moment.unix(value.startDate).format(),
            "Working Country": value.workingCountry,
            "Selling Price": value.jobPrice,
            "Approved At": moment().format().toString(),
          });
          value.jobStatus = 4;
          this.loading = false;
          this.x.success = true;
        },
        (error) => {
          this.loading = false;

          this.error = error.response.data.message;
        }
      );
    },

    page(event) {
      this.pages = event;
    },
    inquiryApproval(value, status) {
      this.loading = true;
      let body = {
        inquiryId: value.inquiryId.toString(),
        underApproval: true,
      };
      ApiService.postData("/approvals/approveInquiry", body).then(
        (response) => {
          value.jobStatus = "Approved";
          this.identify(localStorage.getItem("email"));
          this.track("Inquiry Approval", {
            "Inquiry Id": value.inquiryId,
            "Company Name/Customer Name":
              value.userId.companyName ||
              value.userId.customer ||
              value.userId.customerEmail,
            "Job Start Date": value.bookingDateTime,
            "Working Country": value.workingCountry,
            "Selling Price": value.quotedPrice,
            "Approved At": moment().format().toString(),
          });
          this.loading = false;
          this.x.success = true;
        },
        (error) => {
          this.loading = false;

          this.error = error.response.data.message;
        }
      );
    },
    open3(val, index) {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }
      if (!this.cancelType) {
        this.error = "Please select cancellation reason before moving ahead";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return false;
      }
      if (this.cancelType.value == "Other’s") {
        if (!this.cancelComments.trim()) {
          this.error = "Please enter cancellation reason before moving ahead";
          setTimeout(() => {
            this.error = "";
          }, 3000);
          return false;
        }
      }

      this.dialog3 = true;
      this.dialog2 = false;
      this.cancelTitle1 = "Cancellation Message";

      if (this.tabFilter == "sales") {
        this.cancelMessage1 = "Your  Inquiry has been cancelled!";
      } else {
        this.cancelMessage1 = "Your  job has been cancelled!";
      }
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    open2(val, index) {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }
      this.currentEnquiry = val;
      this.currentIndex = index;

      this.cancelComments = "";
      this.cancelType = "";

      this.dialog2 = true;
      this.cancelTitle = "Cancel Job";
      this.cancelMessage = "On clicking YES this job will be cancelled";
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree2() {
      this.resolve(true);
      this.cancelJob(this.currentEnquiry, this.currentStatus);
      this.dialog2 = false;
    },
    cancel2() {
      this.resolve(false);
      this.dialog2 = false;
    },
    cancelJob(value, status) {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }
      this.loading = true;
      let body = {
        jobId: this.items[this.currentIndex].jobId.toString(),
        type: 2,
      };
      if (this.cancelType.value) {
        if (this.cancelType.value == "Other’s") {
          body.cancellationReason =
            this.cancelType.value + " - " + this.cancelComments.trim();
        } else {
          body.cancellationReason = this.cancelType.value;
        }
      }

      ApiService.postData("/dmsAdmin/cancelJob", body).then(
        (response) => {
          this.componentKey += 1;
          this.loading = false;
          this.dialog3 = false;
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
          setTimeout(function () {
            this.error = "";
          }, 3000);
        }
      );
    },
    getStatus(status) {
      switch (status) {
        case 1:
          return "Approved";
        case 2:
          return "Approved";
        case 3:
          return "Approved";
        case 4:
          return "Approved";
        case 5:
          return "Approved";
        case 6:
          return "Approved";
        case 7:
          return "Approved";
        case 8:
          return "Approved";
        case 9:
          return "Approved";
        case 10:
          return "Low Credit Limit";

        case -1:
          return "Cancelled";
        default:
          return "Unknown";
      }
    },
    goToProfile(jobId) {
      this.$router.push(`/jobs/${jobId}`);
    },
    paginationPayload(event) {
      this.payload = event;
    },
    closeMessage() {
      this.x.success = false;
      this.componentKey += 1;
      this.$emit("on-block");
    },
    getTime3(date) {
      return moment.utc(date).format("ll");
    },

    getTime(date) {
      return moment(date).format("ll");
    },
    callback(res) {
      this.$emit("off-load");
      this.loading = false;
      if (this.tabFilter == "job") {
        this.items = [...res.jobInfo];
      }
      if (this.tabFilter == "sales") {
        this.items = [...res.jobInfo];
      }

      this.totalPages = Math.ceil(res.totalData / 15);
      this.pagination.rowsPerPage = 15;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss">
#dataTable .theme--light.v-datatable thead th.column.sortable .v-icon {
  opacity: 0.3 !important;
  color: white !important;
  margin-left: 10px !important;
}
#dataTable.theme--light.v-datatable thead th.column.sortable.active .v-icon {
  opacity: 1 !important;
  color: white !important;
  margin-left: 10px !important;
}
</style>

<style scoped>
.cancel-btn {
  text-decoration: none;
  color: #ffffff;
  border: 2px solid red;
  padding: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #f38282;
  border-radius: 5px;
}
.m-l-5 {
  margin-left: 5px;
}
.v-datatable thead th.column.sortable .v-icon {
  opacity: 0.3 !important;
}
</style>
