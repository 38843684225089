<template>
  <v-container fluid>
    <v-layout class="errorbox" v-if="noTrue">
      <v-flex
        >Seems like you don't have any permissions. Please contact
        administrator</v-flex
      >
    </v-layout>
    <v-card raised v-if="!noTrue">
      <loading :active.sync="loading" />
      <v-tabs
        style="
          text-align: center;
          align-items: center;
          justify-content: center;
          display: flex;
        "
        v-model="TabStatus"
        fixed-tabs
        background-color="darkorange"
        dark
      >
        <v-tab
          :href="`#job`"
          v-permissions="'job-approval'"
          @click="checkJob('job')"
          >JOBS <span v-if="newJob" class="tab-span">{{ newJob }}</span></v-tab
        >
        <v-tab
          :href="`#sales`"
          v-permissions="'sales-approval'"
          @click="checkSales('sales')"
          >SALES
          <span v-if="newInquiry" class="tab-span">{{
            newInquiry
          }}</span></v-tab
        >
      </v-tabs>

      <CustomFilter
        v-on:customer-search="searchText"
        v-on:date-Filter="dateFilter"
        v-on:daterangenew-cust="dateRangenew"
        v-on:customer-reset="resetCustomer"
      />

      <approvalsList
        :tabFilter="currentTab"
        :searchText="search"
        :dateRangenew="date2"
        :dateFilter="dateRange"
        :reset="reset"
        :loader="loading"
        v-on:on-block="refreshActivity"
        v-on:on-load="startLoad"
        v-on:off-load="stopLoad"
        :updateList="updateTable"
      />
    </v-card>
  </v-container>
</template>

<script>
import { StorageKeys } from "../../constants/constants";
import { EventBus } from "../../event-bus.js";
import Loading from "vue-loading-overlay";
import Swal from "sweetalert2";
import approvalsList from "./approvalsList";
import CustomFilter from "./CustomFilter";
import checkPermissionsMixin from "@/mixins/checkPermissionsMixin";
import moment from "moment";
export default {
  mixins: [checkPermissionsMixin],
  created() {
    this.$eventBus.$on("job-approval-notification", (value) => {
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        let workingCountry = [];
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            workingCountry.push(key.value);
          }
        });
        this.workingCountry = JSON.stringify(workingCountry);
      }
      if (this.workingCountry.includes(value)) {
        this.currentTab = "job";
        this.TabStatus = "job";
        this.newJob = this.newJob + 1;
        this.updateTable = this.updateTable + 1;
      }
    });
    this.$eventBus.$on("sales-approval-notification", (value) => {
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        let workingCountry = [];
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            workingCountry.push(key.value);
          }
        });
        this.workingCountry = JSON.stringify(workingCountry);
      }
      if (this.workingCountry.includes(value)) {
        this.newInquiry = this.newInquiry + 1;
        this.updateTable = this.updateTable + 1;
        this.currentTab = "sales";
        this.TabStatus = "sales";
      }
    });

    let userPermissions = JSON.parse(
      localStorage.getItem("permissions")
    ).permissions;
    userPermissions.filter((x) => {
      if (x.operationName == "job-approval") {
        this.currentTab = "job";
        this.TabStatus = "job";
      }
    });
    this.$eventBus.$on("on-load", () => {
      this.loading = true;
    });

    this.$eventBus.$on("off-load", () => {
      this.loading = false;
    });

    if (localStorage.getItem("workingCountries") !== null) {
    }
    EventBus.$on("countryChanged", (val) => {
      this.newInquiry = 0;
      this.newJob = 0;
      this.selectedCountry = val.value;
    });
    if (this.$route.query && this.$route.query.type) {
      if (this.$route.query.type === "job") {
        this.currentTab = "job";
        this.TabStatus = "job";
      } else if (this.$route.query.type === "sales") {
        this.currentTab = "sales";
        this.TabStatus = "sales";
      } else {
        this.currentTab = "sales";
        this.TabStatus = "sales";
      }
    }
    if (this.$route.query && this.$route.query.workingCountry) {
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            this.workingCountry = key.value;
          }
        });
      }

      let key = this.$route.query.workingCountry;
      EventBus.$emit("setCountriesfromUrl", key);
      this.workingCountries.forEach((element, index) => {
        if (element.value === key) {
          element.isSelected = true;
          this.newInquiry = 0;
          this.newJob = 0;
        } else element.isSelected = false;
      });
      localStorage.setItem(
        "workingCountries",
        JSON.stringify(this.workingCountries)
      );
    }
  },

  components: {
    approvalsList,
    CustomFilter,
    Loading,
  },
  data: () => ({
    operationName: "",
    showScreen: true,
    search: "",
    date2: null,
    noTrue: false,
    dateRange: { startDate: "", endDate: "" },
    reset: false,
    newJob: 0,
    newInquiry: 0,
    active: "0",
    dialog: false,
    loading: true,
    selectedCountry: null,
    currentTab: "sales",
    TabStatus: "sales",
    updateTable: 0,
  }),
  methods: {
    async checkJob(section) {
      this.newJob = 0;

      this.operationName = "job-approval";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.viewTab(section);
      } else {
        return;
      }
    },
    async checkSales(section) {
      this.newInquiry = 0;
      this.operationName = "sales-approval";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.viewTab(section);
      } else {
        return;
      }
    },

    refreshActivity() {
      this.reset = true;
    },
    startLoad() {
      this.loading = true;
    },

    stopLoad() {
      this.loading = false;
    },
    resetCustomer(event) {
      this.reset = true;
      this.search = "";
      this.date2 = null;
    },
    dateRangenew(event) {
      this.date2 = event;
      this.reset = false;
    },

    searchText(event) {
      this.search = event;
      this.reset = false;
    },
    dateFilter(event) {
      this.dateRange = event;

      this.reset = false;
    },
    viewTab(flag) {
      if (flag == "job") {
        this.$router.replace({ name: "Approvals", query: { type: "job" } });
      } else {
        this.$router.replace({ name: "Approvals", query: { type: "sales" } });
      }
      this.currentTab = flag;
    },
  },
  watch: {
    "$route.query"() {
      if (this.$route.query && this.$route.query.type) {
        if (this.$route.query.type === "job") {
          this.currentTab = "job";
          this.TabStatus = "job";
        } else if (this.$route.query.type === "sales") {
          this.currentTab = "sales";
          this.TabStatus = "sales";
        } else {
          this.currentTab = "sales";
          this.TabStatus = "sales";
        }
      }
    },
  },
};
</script>

<style>
.daterangepicker.openscenter[data-v-267f4ee2] {
  position: absolute;
  top: 35px;
  left: 80%;
}
.reportrange-text[data-v-267f4ee2] {
  background: #fff;
  cursor: pointer;
  position: absolute;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
  width: 250px;
}

.drp-calendar {
  margin-right: 80px !important;
}

.daterangepicker.openscenter[data-v-267f4ee2] {
  right: auto;
  left: 62%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}
.errorbox {
  padding: 20px;
  text-align: center;
  color: red !important;
  font-size: 20px;
  padding: 10px 10px 10px 10px !important;
}
</style>
