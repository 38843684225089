import { render, staticRenderFns } from "./approvalsList.vue?vue&type=template&id=f5ed4362&scoped=true&"
import script from "./approvalsList.vue?vue&type=script&lang=js&"
export * from "./approvalsList.vue?vue&type=script&lang=js&"
import style0 from "./approvalsList.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./approvalsList.vue?vue&type=style&index=1&id=f5ed4362&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5ed4362",
  null
  
)

export default component.exports