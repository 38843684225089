<template>
  <v-container fluid>
    <v-layout>
      <v-flex xs4>
        <v-text-field
          v-model="search"
          label="Search by Anything"
          @keyup.enter="searchAnything"
        ></v-text-field>
      </v-flex>
      <v-flex
        xs3
        pl-5
        style="padding-top: 18px"
        class="wrapper"
        id="approvalDate"
      >
        <ejs-daterangepicker
          id="approvalPicker"
          :placeholder="waterMark"
          v-model="dateRange2"
        ></ejs-daterangepicker>
      </v-flex>
      <v-flex xs5 class="reset">
        <v-btn
          class="reset-btn-color"
          flat
          prepend-icon
          color="orange darken-1"
          @click.native="reset()"
        >
          Reset
          <v-icon color="orange">donut_large</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import moment from "moment";
import Loading from "vue-loading-overlay";
import Vue from "vue";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";

Vue.use(DateRangePickerPlugin);
import { EventBus } from "../../event-bus.js";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually (in case you want to override it)
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  name: "approvalFilterCustomer",
  components: {
    DateRangePicker,
  },
  data() {
    return {
      picker: {},
      waterMark: "Select a Range",
      dateRange2: null,
      startDate: "",
      endDate: "",
      dateRange: { startDate: "", endDate: "" },
      search: "",
      select: { country: "", abbr: "" },
    };
  },

  created() {
    EventBus.$on("countryChanged", (val) => {});
  },
  watch: {
    dateRange2() {
      this.$emit("daterangenew-cust", this.dateRange2);
      // localStorage.setItem("approvalStartdate", this.dateRange.startDate);
      // localStorage.setItem("approvalEndDate", this.dateRange.endDate);
    },
  },
  methods: {
    reset() {
      // localStorage.removeItem("approvalSearch");
      // localStorage.removeItem("approvalStartdate");
      // localStorage.removeItem("approvalEndDate");

      this.$emit("customer-reset", {
        search: this.search,
        dateRange: this.dateRange,
      });
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
      this.dateRange2 = null;
      this.search = "";
    },
    startDateFilter() {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        this.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD");
        this.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD");
        this.$emit("date-Filter", {
          startDate: this.startDate,
          endDate: this.endDate,
        });
      }
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    searchAnything() {
      //localStorage.setItem("approvalSearch", this.search);
      this.$emit("customer-search", this.search);
    },
  },
};
</script>
<style scss>
div#approvalPicker_popup {
  left: 562.188px !important;
}
#approvalDate .e-input-group-icon.e-range-icon.e-icons::after {
  content: none !important;
}

#approvalDate span.e-input-group-icon.e-range-icon.e-icons {
  position: absolute;
  left: 0;
  right: 100px !important;
  width: 100%;
  text-align: right;
}
#approvalDate .e-input-group-icon.e-range-icon::before,
*.e-control-wrapper .e-input-group-icon.e-range-icon::before {
  content: "\e245";
  font-family: "e-icons";
  float: right;
  position: absolute;
  right: 0;
}
#approvalDate .e-daterangepicker {
  left: inherit !important;
  right: 120px;
}
.e-daterangepicker.e-popup .e-calendar,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar {
  background-color: #fff;
  height: 100%;
}
#approvalDate .e-clear-icon {
  display: none !important;
  opacity: 0;
}
</style>
<style scoped>
.reset {
  margin-top: 10px;
  text-align: end;
}
</style>
